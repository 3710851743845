import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import useWeb3Forms from "@web3forms/react";
import React from 'react';
import "./ContactPageStyles.css";


const ContactPage = () => {

  const textAreaRef = useRef(null);

  const handleInput = (event) => {
    const textarea = textAreaRef.current;
    textarea.style.height = "auto"; 
    textarea.style.height = `${textarea.scrollHeight}px`; 
  };

  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "b422811f-fb4b-459b-9460-f19c37952621");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };



  return (
    <div className="ContactForm">
    <div className="contactland">
      <div className="contactmask">
        <section className="contact-form">
          <form onSubmit={onSubmit}>
            <h1>Contact Form</h1>
            <div className="input">
              <label>Full Name</label>
              <input
                type="text"
                name="name"
                id="name"
                className="textarea"
                placeholder="Enter your name"
                required
              />
            </div>
            <div className="input">
              <label>Email address</label>
              <input
                type="email"
                name="email"
                id="email"
                className="textarea"
                placeholder="Enter your Email address" 
                required
              />
            </div>
            <div className="input">
              <label>Your message</label>
              <textarea
                name="message"
                id="message"
                className="field mess"
                placeholder="Enter your message"
                ref = {textAreaRef}
                onInput={handleInput}
                required
              ></textarea>
            </div>
            <button className="btn" type="submit">
              Send Message
            </button>
            <p>{result}</p>
          </form>
        </section>
      </div>
    </div>
    </div>
  );
};

export default ContactPage;

